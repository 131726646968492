
* {
	margin: 0;
	padding: 0;
	list-style: none;
	text-decoration: none;
	outline: 0;
	border: 0;
	font-family: "gordita_regular";
	font-style: normal;
	background: 0 0;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}
html.modal-open {
	overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "gordita_medium";
}
p {
	font-family: "gordita_regular";
}
img {
	display: block;
	width: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
	
}
.OtpStyle {
	/* justify-content: center; */
	width: 80%;
	/* margin: 0 auto 15px; */
	margin-bottom: 15px;
}
.OtpInput {
	/* font-family: "gordita_regular"; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: 100;
	width: 50px !important;
	height: 50px;
	border: 1px solid #c9c6c6;
	border-radius: 6px;
	margin-right: 5px;
	font-weight: 400;
	font-size: 20px;
}
.OtpInput::last-child {
	margin-right: 0;
}
.OtpInput::placeholder {
	color: #242020;
	font-size: 18px !important;
}
.OtpInput:focus {
	outline: none;
	
	border: 1px solid #0FA76F
}
@keyframes semirotation {
	0% {
		transform: rotate(-15deg);
	}
	50% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(-15deg);
	}
}
html {
	scrollbar-width: none;
	scroll-behavior: smooth;
}

@font-face {
	font-display: auto;
	font-family: gordita_light;
	src: url(../fonts/gordita/GorditaLight.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: gordita_regular;
	src: url(../fonts/gordita/GorditaRegular.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-display: auto;
	font-family: gordita_medium;
	src: url(../fonts/gordita//GorditaMedium.woff) format("woff");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: gordita_bold;
	src: url(../fonts/gordita/GorditaBold.woff) format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "EGGIndulekhaUni";
	src: url(../fonts/Indulekha/EGGIndulekhaUniB.woff2) format("woff2");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "IndulekhaBold";
	src: url(../fonts/Indulekha/indulekhaHeavyBold.woff2) format("woff2");
	font-weight: normal;
	font-style: normal;
}

select {
	/* for Firefox */
	-moz-appearance: none;
	/* for Chrome */
	-webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
	display: none;
}

.wrapper {
	width: 90%;
	margin: 0 auto;
	max-width: 1380px;
}
@media all and (max-width: 480px) {
	/* .wrapper {
        width: 90%;
    } */
}

/* video player */

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	outline: 0;
}

.progress-control {
	z-index: 50;
	opacity: 1;
	transition: all ease 0.5s;
	transform: unset;
}
.progress-control .hide {
	transform: translateY(20px);
	z-index: 10;
	opacity: 0;
	transition: all ease 0.5s;
}
.video-react {
	background-color: transparent !important;
}
.video-react .video-react-big-play-button {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	line-height: 60px;
	border: none;
}
.hide-player {
	display: none;
}
.video-react .video-react-control-bar {
	flex-wrap: wrap;
}

.video-react .video-react-progress-control {
	align-items: flex-start;
	width: 100%;
}
.video-react .video-react-control {
	height: auto;
}

/* .video-react .video-react-slider {
    margin: 0;
} */
.video-react .video-react-progress-holder {
	height: 5px;
}

.video-react .video-react-progress-holder .video-react-play-progress,
.video-react .video-react-progress-holder .video-react-load-progress,
.video-react .video-react-progress-holder .video-react-tooltip-progress-bar,
.video-react .video-react-progress-holder .video-react-load-progress div {
	height: 5px;
}

.video-react .video-react-play-progress:before {
	font-size: 17px;
	color: #15bf81;
}

.video-react .video-react-progress-holder .video-react-play-progress {
	background: #0fa76f;
}

.video-react .video-react-slider:focus {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.video-react .video-react-control-bar {
	height: 40px;
}
.fullscreen {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

#video-player .video-react .video-react-play-progress:before {
	color: #15bf81;
	font-size: 20px;
	line-height: 16px;
}
#video-player .video-react .video-react-mouse-display:after,
#video-player .video-react .video-react-play-progress:after,
#video-player .video-react .video-react-time-tooltip {
	font-family: "gordita_regular";
}
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-play-progress {
	background: linear-gradient(
		272deg,
		rgba(34, 193, 195, 1) 0%,
		rgba(99, 187, 76, 1) 0%,
		rgba(24, 152, 175, 1) 100%
	);
}
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-play-progress,
#video-player .video-react .video-react-progress-holder,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-load-progress,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-tooltip-progress-bar,
#video-player
	.video-react
	.video-react-progress-holder
	.video-react-load-progress
	div {
	height: 3px;
	border-radius: 15px;
}
#video-player .video-react .video-react-load-progress {
	background: #f5f5f5;
}

#video-player
	.video-react
	.video-react-big-play-button.video-react-big-play-button-center {
	top: 50%;
	left: 50%;
	margin-top: unset;
	margin-left: unset;
	transform: translate(-50%, -50%);
}
#video-player.hidden .video-react .video-react-control-bar {
	background: rgba(0, 0, 0, 0) !important;
	transition: all ease 0.5s;
	opacity: 0;
}

#video-player .video-react .video-react-control-bar {
	height: 100%;
	background: rgba(0, 0, 0, 0.25);
	transition: all ease 0.5s;
	opacity: 1;
}
#video-player .video-react .video-react-control-bar .bottom {
}
/* #video-player .video-react {
  font-size: 13px;
  font-family: unset;
  padding-top: 56.25%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */
#video-player .video-react .video-react-control {
	padding: 0 30px;
	height: 63px;
	position: absolute;
	bottom: 0;
}
@media only screen and (max-width: 640px) {
	#video-player .video-react .video-react-control {
		padding: 0 30px;
		height: 45px;
		position: absolute;
		bottom: 0;
	}
	.video-react .video-react-play-progress:before {
		font-size: 17px;
		color: #15bf81;
	}
	#video-player .video-react .video-react-play-progress:before {
		color: #15bf81;
		font-size: 17px;
		line-height: 15px;
	}
}

@media only screen and (max-width: 480px) {
	#video-player .video-react .video-react-control {
		padding: 0 10px;
		height: 40px;
	}
	.video-react .video-react-play-progress:before {
		font-size: 15px;
		color: #15bf81;
	}
	#video-player .video-react .video-react-play-progress:before {
		color: #15bf81;
		font-size: 15px;
		line-height: 13px;
	}
	br.meet {
		display: none;
	}
}
#video-player .video-react .slide-left {
	transform: translateX(-100%);
	transition: transform 500ms ease-in-out;
}
.play-pause {
	display: flex;
	align-items: center;
	justify-content: center;
}
.play-pause label {
	display: block;
	box-sizing: border-box;
	width: 0;
	height: 25px;
	cursor: pointer;
	border-color: transparent transparent transparent #fff;
	transition: 100ms all ease;
	will-change: border-width;
	border-style: double;
	border-width: 0px 0 0px 34px;
}
.play-pause input[type="checkbox"] {
	visibility: hidden;
}
.play-pause input[type="checkbox"]:checked + label {
	border-style: solid;
	border-width: 22px 0 22px 36px;
}

#video-player .v-play-button {
	border: 0;
	background: transparent;
	box-sizing: border-box;
	width: 0;
	height: 25px;
	border-color: transparent transparent transparent #fff;
	transition: 100ms all ease;
	cursor: pointer;
	border-style: solid;
	border-width: 25px 0 25px 39px;
}
#video-player .v-play-button.playing {
	border-style: double;
	border-width: 0px 0 0px 41px;
}
#video-player .v-play-button:hover {
	border-color: transparent transparent transparent #fff;
}

#invite-video .player-wrapper {
	position: relative;
	/* padding-top: 56.25% !important; */
	/* 720 / 1280 = 0.5625 */
}

#invite-video .react-player {
	position: absolute;
	top: 0;
	left: 0;
}

@media (max-width: 360px) {
	span.location {
		width: 22px;
	}
}
.gradient {
	height: 120px;
	width: 100%;
	border-radius: 10px;
	background: linear-gradient(to right, #003c3c, #047e7e, #003c3c);
	background-size: 110% 20%;
	animation: gradient 2s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 200% 100%;
	}

	100% {
		background-position: 0% 50%;
	}
}
html.modal-enabled {
    overflow: hidden !important;
}

